import Layout from "../../../templates/layout";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

if (process.env.WEBPACK) require("../../../styles/common.scss");

export default function LiveZoomDocs() {
  return (
    <Layout>
      <div className='connect-to-zoom-docs'>
        <div className='max-width-div'>
          <h2>Connect with Zoom as Streaming Software</h2>
          <p>
           You can also use VdoCipher’s Live Streaming with Zoom as the input streaming software. 
           In this case, Zoom’s input features, UI (e.g., multiple attendees input), can be used but the streaming and player part will be handled by VdoCipher.
           So, you need not buy the costlier Zoom’s plan for streaming to additional attendees per meeting, as the increased users will get handled via VdoCipher’s live stream.
           A basic Zoom Pro subscription will be enough. Example - Multiple teachers/presenters can connect on Zoom and set up multiple screens and you can directly broadcast this live stream on your website with our services.
           Below are the detailed steps to configure this.
          </p>
          <h4>Step 1- Configure your Zoom Account</h4>
          <p>
          Ensure that you are using Zoom's Pro Plan or higher and have  <b>enabled Live Streaming for meetings</b> on your account. 
            <a href='https://support.Zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0059839' target='_blank' rel="noopener noreferrer">This document on Zoom</a> shows how to enable custom live streaming connection with Zoom meetings.
          </p>
          <h4>Step 2- Create and start a Zoom meeting</h4>
          <p>
          Create a new Zoom meeting that you want to live stream. Open the latest version of the Zoom client and start the meeting. 
          </p>
          <h4>Step 3- Configure Live Streaming</h4>
          <ul>
            <li>Click the three dots in the lower right-hand corner of the action bar and then click <b> <i>Live on Custom Live Streaming Service.</i></b></li>
            <StaticImage className='doc-image' width={700} src="../../../img/livestream/zoom-configs.png" alt="how to connect Zoom with VdoCipher"/>
            <li>You will be then presented with a Zoom browser page to fill in your live streaming server’s information. You can enter the fields manually by getting the details after creating a live stream on your VdoCipher Dashboard. </li>
          </ul>
          <p><i>Note: Initiating a stream takes up to 90 seconds and it will show status as “preparing” for that duration. Once the stream is ready you will get the Show Stream credentials Option.</i></p>
          <StaticImage className='doc-image' width={700} src="../../../img/livestream/livestream-credentials.png"/>
          <ol>
            <li>In the Streaming URL you need to fill the Server URL generated for your live stream.</li>
            <li>In the Steaming Key you need to enter the Stream key.</li>
            <li>The Live Streaming Page URL is the URL of your website where your viewers can view your stream. This will only be visible to the people connected over Zoom, not the people watching the live stream.</li>
            <li>Click Go Live! , Your Zoom meeting is now connected to the VdoCipher live streaming.</li>
          </ol>
          <h4>Step 4 - Embedding this live stream on your website/app</h4>
          <p>
            Now you need to go to the VdoCipher dashboard and click on the embed button on your live stream. Below is a screenshot showing the same. 
          </p>
          <StaticImage className='doc-image' width={700} src="../../../img/livestream/livestream-embed.png"/>
          <p>
            Once you use this iframe code on your website/app pages, the viewers can directly stream the videos from the page where the iframe is embedded.
          </p>
          <StaticImage className='doc-image' width={700} src="../../../img/livestream/zoom-meeting.jpg"/>
          <p className='note-heading'>Important note about streaming key and url in Zoom setup</p>
          <p>
            The Streaming URL, Steaming Key and Live Steaming Page URL used for broadcasting the video get saved on the Zoom's meeting. For recurring meetings, if you need to update it later, you can go to the Zoom meeting settings page. There you can manually update it for every new meeting or remove if necessary. You can click on the edit button and update the values for new streams.
          </p>
          <StaticImage className='doc-image' width={700} src="../../../img/livestream/zoom-livestreaming-setup.png"/>
        </div>
      </div>
    </Layout>
  )
}
